<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Discounts" subtitle="Manage the discounts available to attendees when purchasing badges or items." :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.status" text="Status" tooltip="Select status" :options="$util.options($constants.registration.discount.statusName)" />
		<app-content-filters-checklist v-model="filter.type" text="Type" tooltip="Select types" :options="$util.options($constants.registration.discount.typeName)" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no discounts found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column />
				<app-table-column text="Name" />
				<app-table-column text="Code" />
				<app-table-column text="Type" />
				<app-table-column border="left" align="center" text="Uses" />
				<app-table-column border="right" align="center" text="Limit" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-label text="Online" type="success" v-if="item.status === 1" />
					<app-table-cell-label text="Offline" type="danger" v-if="item.status === 0" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.code" />
					<app-table-cell-text :text="$constants.registration.discount.typeName[item.type]" />
					<app-table-cell-text border="left" align="center" :text="item.count.uses" />
					<app-table-cell-text border="right" align="center" :text="item.uses.all.amount" v-if="item.uses.all.enabled" />
					<app-table-cell-icon border="right" align="center" icon="unlimited" v-if="!item.uses.all.enabled" v-tooltip="'Unlimited'" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			filter: {
				status: [],
				type: []
			},
			layout: '64px auto 120px 120px 80px 80px 24px',
			endpoint: 'convention/registration/discounts',
			live: 'convention/registration/discounts'
		}

	}

}

</script>

<style scoped>

</style>